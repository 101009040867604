import { useCallback, useMemo } from 'react'

import config from '@config'
import ancillaryUtils from '@lib/ancillary'
import { BookingFormData } from '@pages/Booking/hooks/useInitialFormValues'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

interface ShowExtrasData {
  availableExtras: KnownAncillary[]
  showExtras: boolean
  showSeats: boolean
  showAncillaries: boolean
}

interface useShowExtrasProps {
  values: CheckoutFormData | BookingFormData
  outbound: Connection | null
  inbound: Connection | null
}

const categories: KnownAncillary[] = [
  'BICYCLE',
  'LUGGAGE',
  'PETS',
  'EQUIPMENT',
  'VEHICLE',
  'INSURANCE',
  'SIMPLIFIED_INSURANCE',
  'MEAL',
  'CARBON',
  'HELP',
  'SEAT',
  'METRO',
  'LEAD',
  'BOARDING',
  'LOUNGE',
  'FASTTRACK',
  'TICKETSBYPOST',
  'CHANGE',
  'YOUNGCOACHCARD',
  'SENIORCOACHCARD',
  'DISABLEDCOACHCARD',
  'FREESEAT',
]

export const useShowExtras = ({ values, outbound, inbound }: useShowExtrasProps): ShowExtrasData => {
  const [{ seatSelection, reservation, ancillaries, quickReservation }] = useSettings()
  const { vacancy, isVacancyLoading } = values
  const [{ retailerPartnerNumber: RPN, seats, marketingCarrierCode: carrierCode }] = useParams()
  const { forcedExtras } = config

  const isAncillaryAvailable = useCallback(
    (category: AncillaryCategory): boolean => {
      const limitation = outbound && ancillaryUtils.getLimitation(category, outbound)
      const segmentsCount = [outbound, inbound].reduce((acc, curr) => (curr ? acc + curr.segments.length : acc), 0)
      const ancillaries = ancillaryUtils.getByCategory(category, vacancy?.ancillaries)
      const isAvailable = !!ancillaryUtils.filterBySegment(ancillaries, segmentsCount).length

      // istanbul ignore next
      if (category === 'SEAT' && config.seatAncillaryAsSelection?.[carrierCode || '']) {
        return false
      }

      return limitation !== null ? limitation && isAvailable : isAvailable
    },
    [inbound, carrierCode, outbound, vacancy?.ancillaries],
  )

  // istanbul ignore next
  const showSeats = useMemo(() => {
    const { forcedExtras, seatAncillaryAsSelection } = config
    const isSeatAncillary = ancillaryUtils.getByCategory('SEAT', vacancy?.ancillaries).length > 0

    if (forcedExtras?.[RPN]?.seatSelection?.enabled) return true
    if ((reservation.enabled || quickReservation.enabled) && !!seats) return true
    if (seatAncillaryAsSelection?.[carrierCode || ''] && isSeatAncillary) return true

    return seatSelection.enabledOnCheckout
  }, [vacancy, seatSelection, RPN, reservation, quickReservation, seats, carrierCode])

  const availableExtras = useMemo<KnownAncillary[]>(
    () => categories.filter(item => isAncillaryAvailable(item)),
    [isAncillaryAvailable],
  )

  const showAncillaries = availableExtras.length > 0 || !!forcedExtras?.[RPN]?.testAncillaries?.enabled

  const showExtras = [showAncillaries, ancillaries.enabled && isVacancyLoading].some(item => item)

  return {
    availableExtras,
    showExtras,
    showSeats,
    showAncillaries,
  }
}
