import React, { ReactElement, useMemo } from 'react'

import FareConditions from '@components/JourneyCard/Footer/FareConditions'
import Money from '@components/Money'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import connectionUtils from '@lib/connection'
import { useTranslation } from '@lib/i18n'
import seatsUtils from '@lib/seats'
import utils from '@lib/utils'
import { useSettings } from '@queries/settings'
import { Button, Icon } from '@ui'

interface PriceProps {
  connection: Connection
  passengersCount: number
  onClick: (fareClassCode: string) => void
  onToggleDetails: () => void
  fare?: Fare | null
  isAmendment: boolean
  forcedFreeTripPrice?: boolean
}

const Price = (props: PriceProps): ReactElement => {
  const { connection, passengersCount, fare, onClick, isAmendment, onToggleDetails, forcedFreeTripPrice } = props

  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const { totalSeatsLeft } = connection
  const [{ seatSelection }] = useSettings()
  const displayLeftTickets = totalSeatsLeft != null && seatsUtils.showSeatsLeft(totalSeatsLeft)
  const isMultiFareSelection = seatsUtils.hasMultiFareSelection(seatSelection, connection)

  /* istanbul ignore next */
  const handleClick = (): void => {
    onClick(fare?.fareClass.code ?? connection.cheapestFareClassCode)
  }

  const price = useMemo(() => {
    if (forcedFreeTripPrice) return { fractional: 0, currency: connection.cheapestTotalAdultPrice.currency }

    return connectionUtils.getPrice(connection, fare)
  }, [connection, fare, forcedFreeTripPrice])

  return (
    <>
      {!isMultiFareSelection && (
        <div className={bem('journey-card', 'footer-features')}>
          {displayLeftTickets && (
            <div className={bem('journey-card', 'footer-features-tickets-left')}>
              {t('card.ticketsLeft', { count: totalSeatsLeft })}
            </div>
          )}
          <div className="row center items-center">
            <div onClick={onToggleDetails} className={bem('journey-card', 'footer-features-info')}>
              <Icon name="seat" size="medium" />
              <span className={bem('journey-card', 'footer-features-title')}>
                {utils.string.capitalize(fare?.fareClass?.name ?? '')}
              </span>
            </div>
            {fare?.conditions && fare.conditions.length > 0 && <FareConditions conditions={fare.conditions} />}
          </div>
          <div onClick={onToggleDetails} className="journey-card__footer-passengers row">
            <Icon name="passenger" size={isMobile ? 'small' : 'large'} />
            <span>{passengersCount}</span>
          </div>
        </div>
      )}
      <div className={bem('journey-card', 'footer-price', { rounded: isMultiFareSelection })}>
        <Button disabled={connection.bookedOut} onClick={handleClick}>
          <div className={bem('journey-card', 'footer-price-total')}>
            {isMultiFareSelection && <span className="body-16">{`${t('card.priceFrom')} `}</span>}
            <Money {...price} signed={isAmendment} />
          </div>
        </Button>
      </div>
    </>
  )
}

export default Price
