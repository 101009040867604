import { SeatSelectionSetting } from '@api/settings'
import paramsStore from '@stores/params'

const MAX_SEATS_LEFT_SHOW = 9

const showSeatsLeft = (number: number): boolean => {
  const { mode } = paramsStore.get()

  if (number === 0) return false
  if (mode === 'embed') return true

  return number <= MAX_SEATS_LEFT_SHOW
}

const hasMultiFareSelection = (settings: SeatSelectionSetting, connection: Connection | null): boolean =>
  settings.enabledOnSearchResults && !!connection?.offerBundle

const seatsUtils = {
  showSeatsLeft,
  hasMultiFareSelection,
}

export default seatsUtils
