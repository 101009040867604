import React, { ReactElement, useMemo, useState } from 'react'

import FareClassDropdown from '@components/FareClassDropdown'
import Money from '@components/Money'
import FareFeatures from '@components/TripInfo/FareFeatures'
import TripConditions from '@components/TripInfo/TripConditions'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import fareFeaturesUtils from '@lib/fareFeature'
import seatSelectionUtils from '@lib/seatSelection'
import { Icon, Modal, Tabs } from '@ui'

interface Props {
  connection: Connection | null
  segmentLayout: Seat.Data
  value: string
  onChange: (value: string) => void
  showSegmentPrice?: boolean
}

interface FaresProps {
  fares: Fare[]
  value: string
  onChange: (value: string) => void
  showSegmentPrice?: boolean
}

const Desktop = ({ fares, value, onChange, showSegmentPrice }: FaresProps): ReactElement | null => {
  const faresTabs = useMemo(
    () =>
      fares
        .map(item => ({
          value: item.fareClass.code,
          label: (
            <div className="column">
              <span>{item.fareClass.name}</span>
              {showSegmentPrice && (
                <span className="normal">
                  <Money {...item.price} />
                </span>
              )}
            </div>
          ),
        }))
        .filter(item => item.label),
    [fares, showSegmentPrice],
  )

  if (faresTabs.length <= 1) return null

  return <Tabs options={faresTabs} value={value} onClick={onChange} />
}

const Mobile = ({ fares, value, onChange, showSegmentPrice }: FaresProps): ReactElement => {
  const [opened, setOpened] = useState<boolean>(false)

  const selectedFare = fares?.find(item => item.fareClass.code === value)
  const segmentFareFeatures = selectedFare?.fareClass.fareFeatures ?? []

  return (
    <>
      <div className="row space-between items-center">
        <FareClassDropdown value={value} onChange={onChange} fares={fares} showSegmentPrice={showSegmentPrice} />
        <Icon name="info" size="medium" className="ml-2 mr-1" onClick={() => setOpened(true)} />
      </div>
      <Modal
        title={selectedFare?.fareClass.name}
        className={bem('seat-selection', 'tabs-fares-modal')}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <FareFeatures fareFeatures={segmentFareFeatures.filter(item => !fareFeaturesUtils.hasRefundFeatures(item))} />
        <TripConditions fareFeatures={segmentFareFeatures.filter(fareFeaturesUtils.hasRefundFeatures)} description />
      </Modal>
    </>
  )
}

const SeatsFareClasses = (props: Props): ReactElement => {
  const isMobile = useIsMobile()
  const { connection, segmentLayout, ...rest } = props

  const segmentFares = connection?.segments[segmentLayout.segment.index]?.fares
  const connectionFares = connection?.fares

  const fares = segmentFares?.length ? segmentFares : connectionFares
  const seatsFareClasses = seatSelectionUtils.getUniqueFares(props.segmentLayout)

  const filteredFares =
    fares?.filter(item => seatsFareClasses.includes(item.fareClass.code)) ?? /* istanbul ignore next */ []

  return (
    <div className={bem('seat-selection', 'tabs-fares')}>
      {isMobile ? <Mobile fares={filteredFares} {...rest} /> : <Desktop fares={filteredFares} {...rest} />}
    </div>
  )
}

export default SeatsFareClasses
