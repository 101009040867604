import React, { ReactElement, useCallback, useMemo } from 'react'

import { DiscountCardsResponse } from '@api/discountCards'
import { useTranslation } from '@lib/i18n'
import { Dropdown } from '@ui'
import { DropdownItem } from '@ui/Dropdown'

import '@components/Seats/Bus/DiscountsDropdown/index.scss'

interface DiscountsDropdownProps {
  discountCategories: Seat.Discount[]
  passengerCard: string | null
  setPassengerCard: (value: string | null) => void
  hasSelectedSeats: boolean
  discounts: DiscountCardsResponse
}

const DiscountsDropdown = (props: DiscountsDropdownProps): ReactElement => {
  const { discountCategories, passengerCard, setPassengerCard, hasSelectedSeats, discounts } = props

  const { t } = useTranslation()

  const availableCards = useMemo(
    () =>
      discounts.filter(c => c.context === 'pax' && c.cardType === 'discount_card') ??
      /* istanbul ignore next: handle array.find */ [],
    [discounts],
  )

  const getAmountLeft = useCallback(
    (card: string) => discountCategories.find(item => item.name === card)?.meta?.amount_left,
    [discountCategories],
  )

  const cardOptions = useMemo(() => {
    return availableCards.map<DropdownItem<string>>(card => ({
      value: card.name,
      label: (
        <div className="row space-between">
          <span className="mr-1">{card.marketingName}</span>
          <span className="seat-selection__legend-discounts">
            {t('seats.ticketsLeft', { count: getAmountLeft(card.name) })}
          </span>
        </div>
      ),
      selectedLabel: card.marketingName,
    }))
  }, [availableCards, getAmountLeft, t])

  return (
    <div className="seat-selection__card-dropdown">
      <Dropdown
        items={cardOptions}
        label={t('checkout.passengerDetails.discount')}
        value={passengerCard}
        onChange={setPassengerCard}
        onReset={() => setPassengerCard(null)}
        disabled={hasSelectedSeats}
        resettable
      />
    </div>
  )
}

export default DiscountsDropdown
