/* istanbul ignore file */
import currency from 'currency.js'
import { useMemo } from 'react'

import { useAppContext } from '@context/app'
import { FeeData } from '@pages/Checkout/BookingDetails/Fees'
import { useFinalPrice } from '@pages/Checkout/hooks/usePrice'

const feePercentage = 0.16
const gebRpns = [124980, 639526, 748918, 789028, 148759, 584543, 558674, 863261, 719406, 162646, 114621, 770055]

// ToDo: implement it as a carrier plugin after the following ticket:
//  https://distribusion.atlassian.net/browse/OWL-3446
export const useGebExtraFees = (): FeeData[] => {
  const { retailerPartnerNumber } = useAppContext()
  const price = useFinalPrice()

  return useMemo(() => {
    if (!gebRpns.includes(retailerPartnerNumber) || !price?.fractional) return []

    const totalPrice = currency(price.fractional)
    const priceBeforeTax = currency(price.fractional).divide(1 + feePercentage)

    return [
      { code: 'priceBeforeTaxes', amount: { fractional: priceBeforeTax.value, currency: price.currency } },
      { code: 'gebTax', amount: { fractional: totalPrice.subtract(priceBeforeTax).value, currency: price.currency } },
    ]
  }, [price, retailerPartnerNumber])
}
