import React, { ReactElement, ReactNode } from 'react'

import Money from '@components/Money'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import seat from '@lib/seatSelection'
import utils from '@lib/utils'
import { useParams } from '@stores/params'
import { Icon, Modal } from '@ui'

import '@components/Seats/Bus/Legend/index.scss'

interface LegendProps {
  amenities?: ReactNode
  hasPaidSeats: boolean
  hasDiscountedSeats?: boolean
  priceCategories: Record<number, number>
  modal?: boolean
  opened?: boolean
  onClose?: () => void
}

const DISCOUNT_SIGN = '%'

const Content = ({ amenities, hasPaidSeats, priceCategories, hasDiscountedSeats }: LegendProps): ReactElement => {
  const [{ currency }] = useParams()
  const { t } = useTranslation()

  return (
    <div className="seat-selection__legend column row-lg mb-4">
      {amenities && (
        <div className="seat-selection__legend-amenities cell basis-auto">
          <div className="row row gap-2">{amenities}</div>
        </div>
      )}
      <div className="seat-selection__legend-info gap-3 wrap column row-lg">
        {hasDiscountedSeats && (
          <div className="row items-center">
            <div className={bem('seat-selection', 'legend-seat', { discounted: true })}>{DISCOUNT_SIGN}</div>
            <span>{t('seats.discounted')}</span>
          </div>
        )}
        {hasPaidSeats &&
          Object.entries(priceCategories).map(([price, count]) => (
            <div key={price} className="row items-center">
              <div className={bem('seat-selection', 'legend-seat', { paid: true })}>
                <span className="row items-center mb-1 mb-lg-2">
                  {utils.common.times(count, index => (
                    <Icon
                      className={bem('seat-selection', 'legend-seat-sign')}
                      key={index}
                      name={seat.getCurrencyIcon(currency)}
                      size="small"
                    />
                  ))}
                </span>
              </div>
              <span>
                +<Money fractional={Number(price)} currency={currency} />
              </span>
            </div>
          ))}
        <div className="row items-center">
          <div className={bem('seat-selection', 'legend-seat')} />
          <span>{t(hasPaidSeats ? 'seats.free' : 'seats.available')}</span>
        </div>
        <div className="row items-center">
          <div className={bem('seat-selection', 'legend-seat', { disabled: true })}>
            <Icon name="cross" size="medium" />
          </div>
          <span>{t('seats.not_available')}</span>
        </div>
        <div className="row items-center">
          <div className={bem('seat-selection', 'legend-seat', { selected: true })}>
            <Icon name="checkout" size="medium" />
          </div>
          <span>{t('seats.your_seat')}</span>
        </div>
      </div>
    </div>
  )
}

const Mobile = ({ opened, onClose, ...rest }: LegendProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal className="seat-selection__legend-modal" title={t('seats.key')} opened={!!opened} onClose={onClose}>
      <Content {...rest} />
    </Modal>
  )
}

const Legend = ({ modal, ...rest }: LegendProps) => {
  const isMobile = useIsMobile()

  return modal && isMobile ? <Mobile {...rest} /> : <Content {...rest} />
}

export default Legend
