import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react'

import Legend from '@components/Seats/Bus/Legend'
import DeckFrame from '@components/Seats/DeckFrame'
import SeatComponent from '@components/Seats/Item'
import useIsMobile from '@hooks/useIsMobile'
import utils from '@lib/utils'

import '@components/Seats/Bus/Deck/index.scss'

interface DeckProps {
  seats: Seat.Entry[]
  deckSize: Seat.Size
  priceCategories: Record<number, number>
  discountCategories: Seat.Discount[]
  onClick: (seat: Seat.Entry) => void
  selectedSeats: Seat.Selected[]
  amenities?: ReactNode
  legendOpened?: boolean
  passengerCard: string | null
  onLegendClose?: () => void
  startX: number
}

const LAST_ROW = 5
const CENTER_ROW = 2

const updateRow = (seats: Seat.Entry[], i: number): void => {
  const currentSeat = seats[i]
  const prevSeat = seats[i - 1]
  const sub = currentSeat.coordinates.y - 1

  currentSeat.coordinates.y = sub
  if (prevSeat?.coordinates.y === sub) prevSeat.coordinates.y = sub - 1
}

const updateSeats = (seats: Seat.Entry[]): Seat.Entry[] =>
  seats.reduce<Seat.Entry[]>((acc, seat) => {
    if (acc.find(s => s.coordinates.x === seat.coordinates.x)) return acc

    const row = seats
      .filter(s => s.coordinates.x === seat.coordinates.x)
      .sort((a, b) => a.coordinates.y - b.coordinates.y)

    const last = row.findIndex(item => item.coordinates.y === LAST_ROW)
    const center = row.findIndex(item => item.coordinates.y === CENTER_ROW)

    last >= 0 && updateRow(row, last)
    center >= 0 && updateRow(row, center)

    return [...acc, ...row.flat()]
  }, [])

const Deck = (props: DeckProps): ReactElement => {
  const {
    seats,
    priceCategories,
    passengerCard,
    legendOpened,
    onLegendClose,
    onClick,
    selectedSeats,
    amenities,
    discountCategories,
    deckSize,
    startX,
  } = props
  const isMobile = useIsMobile()
  const hasPaidSeats = seats.some(seat => !!seat.price)
  const hasExclusiveSeat = useCallback(
    (discount: Seat.Discount): boolean => !!discount.meta?.exclusive && discount.name === passengerCard,
    [passengerCard],
  )

  const isExclusiveSeats = useMemo(
    () => seats.some(car => car.limitations?.some(hasExclusiveSeat)),
    [hasExclusiveSeat, seats],
  )

  const isDiscountAvailable = useMemo(
    () =>
      discountCategories.some(
        ({ name, meta }) =>
          name === passengerCard && (meta?.amount_left ?? /* istanbul ignore next */ 0) > selectedSeats.length,
      ),
    [discountCategories, passengerCard, selectedSeats],
  )

  const getVacancy = useCallback(
    (seat: Seat.Entry): boolean => {
      if (!discountCategories.length) return seat.vacant
      if (isExclusiveSeats) return !!seat.limitations?.some(hasExclusiveSeat)
      if (isDiscountAvailable || !passengerCard) return !!seat.limitations?.every(item => !item.meta?.exclusive)

      return selectedSeats.some(item => item.code === seat.code)
    },
    [discountCategories, isExclusiveSeats, hasExclusiveSeat, isDiscountAvailable, passengerCard, selectedSeats],
  )

  const getDiscountCategory = useCallback(
    (seat: Seat.Entry): Seat.Discount[] | undefined => {
      if (seat.limitations?.length || isExclusiveSeats) return seat.limitations

      return discountCategories.filter(({ name }) => passengerCard === name)
    },
    [discountCategories, isExclusiveSeats, passengerCard],
  )

  const updatedSeats = useMemo(() => updateSeats(seats), [seats])
  const normalizedSeats = useMemo(() => {
    return utils.array.uniqueBy(
      updatedSeats.map<Seat.Entry>(seat => {
        const x = seat.coordinates.x - startX
        let coordinates = isMobile
          ? { ...seat.coordinates, x: x + 2 }
          : { ...seat.coordinates, x: deckSize.length - x - 2 }

        if (seat.code === 'ES') coordinates = { ...seat.coordinates, x: coordinates.x, y: coordinates.y - 1 }

        return { ...seat, coordinates, orientation: isMobile ? 'facingBackward' : 'facingForward' }
      }),
      'id',
    )
  }, [deckSize.length, isMobile, startX, updatedSeats])

  return (
    <>
      <Legend
        modal
        opened={legendOpened}
        onClose={onLegendClose}
        amenities={amenities}
        hasPaidSeats={hasPaidSeats}
        priceCategories={priceCategories}
        hasDiscountedSeats={discountCategories.length > 0}
      />
      <div className="column items-center pb-3 px-2 overflow-auto">
        <div className="cell">
          <DeckFrame lights bus orientation={isMobile ? 'up' : 'right'} size={deckSize}>
            <SeatComponent
              code="steeringWheel"
              position={isMobile ? { x: 0, y: 0 } : { x: deckSize.length, y: 0 }}
              orientation={isMobile ? 'facingBackward' : 'facingForward'}
            />
            {normalizedSeats.map((seat, index) => (
              <SeatComponent
                key={seat.id}
                code={seat.code}
                price={seat.price}
                priceCategory={priceCategories[seat.price ?? 0]}
                discountCategory={getDiscountCategory(seat)}
                orientation={seat.orientation}
                position={seat.coordinates}
                disabled={!seat.vacant || !getVacancy(seat)}
                label={seat.label}
                onSelect={() => {
                  onClick(seat)
                }}
                selected={selectedSeats.some(item => item.code === seat.code)}
                isFirstSeat={index === 0}
                size={seat.size}
              />
            ))}
          </DeckFrame>
        </div>
      </div>
    </>
  )
}

export default Deck
