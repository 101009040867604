import { CSSProperties } from 'react'
import { UseQueryOptions } from 'react-query'

import { SeatsRequest } from '@api/seats'
import date from '@lib/date'
import utils from '@lib/utils'
import { ParamsStore } from '@stores/params'
import { ServiceSeatCode } from 'src/components/Seats/Item/Service'

const currencyIcons: Record<string, string> = {
  EUR: 'euro',
  USD: 'dollar',
  GBP: 'pound',
}

const supportLabels: string[] = ['WC', 'ES', 'WHEELCHAIR', 'RESTAURANT', 'STROLLER', 'LUGGAGE']

const getCurrencyIcon = (currency: Currency): string => currencyIcons[currency] ?? 'star-simple'

const getSeatsCount = (seats?: Seat.BySegment | null): number => {
  if (!seats) return 0

  return Object.values(seats).reduce((acc, currentValue) => acc + currentValue.length, 0)
}

const getUniqueFares = (layout: Seat.Data): string[] => {
  const array = [...new Set(layout?.cars.flatMap(item => item.seats.map(el => el.fareClass)))]

  return array.filter(item => item !== null) as string[]
}

const getUniqueLevel = (layout: Seat.Data): number[] => [
  ...new Set(layout?.cars.flatMap(item => item.seats.map(el => el.coordinates.z))),
]

export interface BuildSeatsProps {
  limitations?: boolean
  fareClass?: string | null
}

const buildSeatsParams = (
  connection: Connection | Segment,
  params: ParamsStore,
  props?: BuildSeatsProps,
): SeatsRequest => {
  const { locale, currency } = params

  return utils.object.compact<SeatsRequest>({
    marketingCarrierCode: connection.marketingCarrier.code,
    departureStation: connection.departureStation.code,
    arrivalStation: connection.arrivalStation.code,
    departureTime: date.formatDateISO(date.parse(connection.departureTime, 'UTC')),
    arrivalTime: date.formatDateISO(date.parse(connection.arrivalTime, 'UTC')),
    extraFields: props?.limitations ? 'cars.limitations,cars.seats.limitations' : null,
    fareClass: props?.fareClass,
    locale,
    currency,
  })
}

const toUrlParams = (selected: Seat.BySegment): Record<string, Seat.UrlParam[]> => {
  const entries = Object.entries(selected).map(([key, value]) => [
    key,
    value.map(item => ({
      price: item.price ?? 0,
      code: item.code,
      label: item.label,
      fareClass: item.fareClass,
      segmentIndex: item.segmentIndex,
    })),
  ])

  return Object.fromEntries(entries)
}

const filterLayoutByFareClass = (layout: Seat.Data[], fareClassCode: string | null): Seat.Data[] =>
  layout?.map(item => ({
    ...item,
    cars: item.cars.map(car => ({
      ...car,
      seats: car.seats.filter(
        el => el.fareClass === fareClassCode || supportLabels.includes(el.label as ServiceSeatCode),
      ),
    })),
  }))

const sumPrice = (seats: Pick<Seat.Selected, 'price'>[]): number => utils.array.sum(seats, seat => Number(seat.price))

const sumOutboundPrice = (selected: Seat.BySegment, connection: Connection | null, currency: Currency): Money =>
  Object.values(selected)
    .flatMap(item => item.map(el => ({ fareClass: el.fareClass, price: el.price })))
    .reduce(
      (acc, curr): Money => {
        const current = connection?.fares.find(el => el.fareClass.code === curr.fareClass)

        return {
          fractional: acc.fractional + (current?.price.fractional ?? /* istanbul ignore next */ 0) + Number(curr.price),
          currency,
        }
      },
      { fractional: 0, currency },
    )

const MAX_PRICE_CATEGORY = 3

const getPriceCategories = (seats: Seat.Entry[]): Record<number, number> => {
  const prices = seats.filter(seat => seat.price != null && seat.price > 0).map(seat => seat.price as number)

  return [...new Set<number>(prices)]
    .sort((a, b) => a - b)
    .reduce((mem, price, index) => ({ ...mem, [price]: Math.min(index + 1, MAX_PRICE_CATEGORY) }), {})
}

const getSeatsLimitations = (car: Seat.Car): DiscountCard.Item[] =>
  car.seats.flatMap(seat => seat.limitations ?? /* istanbul ignore next */ [])

const getDiscountCategories = (cars: Seat.Car[]): DiscountCard.Item[] => {
  const limitations = cars.flatMap(car => [
    ...getSeatsLimitations(car),
    ...(car.limitations ?? /* istanbul ignore next */ []),
  ])

  return utils.array.uniqueBy(limitations, 'name')
}

const flatten = (seats?: Seat.BySegment): Seat.Selected[] => Object.values(seats ?? {}).flat()

const isLimitedBy = (seat: Seat.Entry, discount: string): boolean =>
  !!utils.array.findBy(seat.limitations ?? /* istanbul ignore next */ [], 'name', discount)
const isLimitedSeatsTaken = (seats: Seat.Entry[], discountCard: string): boolean => {
  let limitedSeatFound = false

  for (const seat of seats) {
    if (isLimitedBy(seat, discountCard)) {
      if (seat.vacant) return false

      limitedSeatFound = true
    }
  }

  return limitedSeatFound
}

const transformIntoTrainSeats = (seats: Seat.Data[]): Seat.Train.Data => {
  const scheme = seats.map<Seat.Train.Segment>(({ segment, cars }) => ({
    index: segment.index,
    cars: cars.map<Seat.Train.Car>(car => ({
      label: String(car.index + 1),
      index: car.index,
      layout: `${segment.index}.${car.index}`,
    })),
  }))

  return {
    scheme: scheme,
    layoutQuery: (layoutKey: string): UseQueryOptions<Seat.Train.Layout> => {
      const [segmentIndex, carIndex] = layoutKey.split('.')
      const segment = seats.find(({ segment }) => segment.index === Number(segmentIndex))!
      const car = segment.cars.find(({ index }) => index === Number(carIndex))!

      const layout = { code: layoutKey, seats: car.seats }

      return {
        queryKey: ['seats', 'layout', 'singleStep', layout],
        queryFn: () => layout,
        placeholderData: layout,
      }
    },
  }
}

const getGridPlacement = (position: Vector, size?: Seat.Size): CSSProperties => ({
  gridRow: `${position.y + 1}${size?.width ? `/${position.y + size.width + 1}` : ''}`,
  gridColumn: `${position.x + 1}${size?.length ? `/${position.x + size.length + 1}` : ''}`,
})

const seatSelectionUtils = {
  transformIntoTrainSeats,
  getSeatsCount,
  getUniqueFares,
  getUniqueLevel,
  toUrlParams,
  buildSeatsParams,
  filterLayoutByFareClass,
  sumPrice,
  sumOutboundPrice,
  getPriceCategories,
  getCurrencyIcon,
  flatten,
  getDiscountCategories,
  isLimitedSeatsTaken,
  getGridPlacement,
}

export default seatSelectionUtils
