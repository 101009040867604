import React, { ReactElement } from 'react'

import Money from '@components/Money'
import { useTranslation } from '@lib/i18n'
import { Dropdown } from '@ui'

import '@components/FareClassDropdown/index.scss'

interface FareClassDropdownProps {
  value: string | null
  onChange: (value: string) => void
  fares: Fare[]
  showSegmentPrice?: boolean
}

const SEPARATOR = ' - '

const FareClassDropdown = ({ value, onChange, fares, showSegmentPrice }: FareClassDropdownProps): ReactElement => {
  const { t } = useTranslation()

  const items = fares.map(fare => ({
    label: (
      <div className="row space-between">
        <span>{fare.fareClass.name}</span>
        {showSegmentPrice && (
          <span>
            <Money {...fare.price} />
          </span>
        )}
      </div>
    ),
    value: fare.fareClass.code,
    selectedLabel: (
      <div className="row mr-1">
        <span className="fare-dropdown__name body-16 bold">{fare.fareClass.name}</span>
        {showSegmentPrice && (
          <>
            <span className="mx-1">{SEPARATOR}</span>
            <span className="body-16 bold">
              <Money {...fare.price} />
            </span>
          </>
        )}
      </div>
    ),
  }))

  return (
    <Dropdown
      className="fare-dropdown"
      label={t('card.fareClass')}
      items={items}
      value={value}
      onChange={onChange}
      popperProps={{ className: 'fare-dropdown__popper' }}
    />
  )
}

export default FareClassDropdown
