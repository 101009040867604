import { useFormikContext } from 'formik'
import React, { ReactElement, useMemo, useState } from 'react'

import config from '@config'
import seat from '@images/extras/seat.png'
import amplitude from '@lib/analytics/amplitude'
import ancillaryUtils from '@lib/ancillary'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import seatsUtils from '@lib/seatSelection'
import Card from '@pages/Checkout/Extras/Card'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import Info from '@pages/Checkout/SeatSelection/Seats/Info'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Icon } from '@ui'

interface PreviewProps {
  onClick: () => void
  outbound: Connection | null
  inbound: Connection | null
  showControls: boolean
  error?: string
}

const Warning = ({ title }: { title: string }): ReactElement => (
  <div className="extras__seats-warning-message seats-error row gap-1">
    <Icon name="alert" size="small" />
    <span>{title}</span>
  </div>
)

const Control = (): ReactElement => (
  <div className={bem('extras', 'seats-icon')}>
    <Icon name="chevron-right" size="large" />
  </div>
)

const Preview = ({ onClick, outbound, inbound, showControls, error }: PreviewProps): ReactElement => {
  const { t } = useTranslation()
  const [touched, setTouched] = useState<boolean>(false)
  const [{ retailerPartnerNumber, marketingCarrierCode }] = useParams()
  const { values } = useFormikContext<CheckoutFormData>()
  const { seats, passengers, vacancy } = values
  const [{ reservation }] = useSettings()

  const isAncillary = config.seatAncillaryAsSelection[marketingCarrierCode!]
  const isAvailable = !config.forcedExtras?.[retailerPartnerNumber]?.seatSelection?.enabled
  const selectedSeatsCount = seatsUtils.getSeatsCount(seats?.outbound) + seatsUtils.getSeatsCount(seats?.inbound)
  const seatAncillary = ancillaryUtils.getByCategory('SEAT', vacancy?.ancillaries)[0]
  const isMultiSegmentSeats = seats?.outbound && Object.keys(seats?.outbound).length > 1

  const handleOpenModal = (): void => {
    if (reservation.enabled) return
    if (!isAvailable) {
      setTouched(true)
      amplitude.checkout.changeAncillary('Seat', passengers.length)
      return
    }

    onClick()
  }

  const errorMessage = useMemo(() => {
    if (touched) return t('seats.warning.unavailable')
    if (error) return t('errors.mismatchSeats')

    return null
  }, [t, touched, error])

  return (
    <Card
      icon={seat}
      title={!isMultiSegmentSeats ? t('extras.seats.selected', { count: selectedSeatsCount }) : ''}
      description={<Info seats={seats} outbound={outbound} inbound={inbound} />}
      controls={showControls && <Control />}
      onClick={handleOpenModal}
      warning={errorMessage && <Warning title={errorMessage} />}
      price={isAncillary ? /* istanbul ignore next */ seatAncillary?.price : undefined}
    />
  )
}

export default Preview
