import React, { Fragment, ReactElement } from 'react'

import SegmentLabel from '@components/Seats/Bus/SegmentLabel'
import FareLabel from '@components/TripInfo/FareLabel'
import { useTranslation } from '@lib/i18n'
import seatUtils from '@lib/seatSelection'
import { Seats } from '@pages/Checkout/hooks/useInitialFormValues'
import Label from '@pages/Checkout/SeatSelection/Seats/Label'
import { Accordion, Divider } from '@ui'

interface InfoProps {
  seats: Seats | null
  outbound: Connection | null
  inbound: Connection | null
}

interface ItemProps {
  connection: Connection
  index: string
  value: Seat.Selected[]
}

const displaySeats = (seats: Seat.Selected[]): string => seats.flatMap(item => item.label).join(', ')

const reduceSeatsByFare = (seats: Seat.Selected[]): Record<string, string[]> => {
  return seats.reduce<Record<string, string[]>>((acc, curr) => {
    /* istanbul ignore next */
    if (!curr.fareClass) return acc

    if (curr.fareClass in acc) acc[curr.fareClass].push(curr.label as string)
    else acc[curr.fareClass] = [curr.label as string]

    return acc
  }, {})
}

const Item = ({ connection, value, index }: ItemProps): ReactElement => (
  <>
    <SegmentLabel
      segment={connection.segments[Number(index)]}
      connection={connection}
      segmentsCount={connection.segments.length}
      currentIndex={Number(index)}
      maxSegmentCount={connection.segments.length}
    />
    {Object.entries(reduceSeatsByFare(value)).map(([key, value]) => {
      const fares = connection.segments.flatMap(item => item.fares)
      const name = fares.find(fare => fare.fareClass.code === key)?.fareClass.name

      return (
        <Fragment key={key}>
          <FareLabel fareClassName={`${name}: ${value.join(', ')}`} />
          <Divider />
        </Fragment>
      )
    })}
  </>
)

const Info = ({ seats, outbound, inbound }: InfoProps): ReactElement => {
  const { t } = useTranslation()

  const outboundSeats = seatUtils.flatten(seats?.outbound)
  const inboundSeats = seatUtils.flatten(seats?.inbound)

  const outboundLabel = seats?.outbound && displaySeats(outboundSeats)
  const inboundLabel = seats?.inbound && displaySeats(inboundSeats)

  const showAccordion =
    (seats?.outbound && Object.keys(seats.outbound).length > 1) ||
    (seats?.inbound && Object.keys(seats.inbound).length > 1)

  const showOutbounds = showAccordion && seats?.outbound && outbound
  const showInbounds = showAccordion && seats?.inbound && inbound

  return (
    <>
      {!showAccordion && (
        <span className="extras__seats-description row-lg column">
          {outboundLabel && <Label type="outbound" label={outboundLabel} />}
          {inboundLabel && <Label type="inbound" label={inboundLabel} />}
          <span>{!outboundLabel && !inboundLabel && t('extras.seats.selection_description')}</span>
        </span>
      )}
      {showOutbounds && (
        <Accordion opened className="extras__seats-accordion" title={t('extras.seats.outbound')} divider={false}>
          <>
            {Object.entries(seats.outbound).map(([key, value]) => (
              <Item key={key} index={key} connection={outbound} value={value} />
            ))}
          </>
        </Accordion>
      )}
      {showInbounds && (
        <Accordion className="extras__seats-accordion" title={t('extras.seats.inbound')} divider={false}>
          <>
            {Object.entries(seats.inbound).map(([key, value]) => (
              <Item key={key} index={key} connection={inbound} value={value} />
            ))}
          </>
        </Accordion>
      )}
    </>
  )
}

export default Info
