import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect } from 'react'

import amplitude from '@lib/analytics/amplitude'
import ancillaryUtils from '@lib/ancillary'
import { useTranslation } from '@lib/i18n'
import AncillaryFieldSet from '@pages/Checkout/Extras/Ancillary/FieldSet'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useShowExtras } from '@pages/Checkout/hooks/useShowExtras'

import '@pages/Checkout/Extras/index.scss'

interface ExtrasProps {
  outbound: Connection | null
  inbound: Connection | null
  preselectedAncillaries?: string[]
}

const Extras = (props: ExtrasProps): ReactElement => {
  const { t } = useTranslation()
  const { preselectedAncillaries, inbound } = props
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const { showAncillaries } = useShowExtras({ values, ...props })
  const isLoading = values.isVacancyLoading || values.isReservationLoading

  useEffect(() => {
    showAncillaries && amplitude.checkout.viewAncillaries()
  }, [showAncillaries])

  useEffect(() => {
    if (!preselectedAncillaries?.length || !showAncillaries) return

    setFieldValue('ancillaries', ancillaryUtils.getPreselected(values, preselectedAncillaries, !!inbound))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.vacancy])

  return (
    <div className="extras cell-12 column">
      <h3>{t('extras.title')}</h3>
      <AncillaryFieldSet isLoading={isLoading} formData={values} {...props} />
    </div>
  )
}

export default Extras
