/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'

import useItemsSelection from '@components/Seats/hooks/useItemsSelection'
import { TrainSeatSelectionContext } from '@components/Seats/Train/context'
import TrainSeatsDesktop from '@components/Seats/Train/Desktop'
import Header from '@components/Seats/Train/Header'
import TrainSeatsMobile from '@components/Seats/Train/Mobile'
import TrainSubmitSection from '@components/Seats/Train/SubmitSection'
import useIsMobile from '@hooks/useIsMobile'
import useLinkedListWithIndex from '@hooks/useLinkedListWithIndex'
import fare from '@lib/fare'
import seatSelectionUtils from '@lib/seatSelection'
import { Modal } from '@ui'

export interface Props extends Seat.Train.Data {
  opened: boolean
  initialSelection?: Seat.ByTripDirection | null
  connection: Connection
  reservedSeatsCount: number
  onSubmit: (data: Seat.SubmitData) => void
  onClose: () => void
  onFareClassChange?: (fareClass: string) => void
  selectedFare: string
  disableFare?: boolean // ?
  onClear?: () => void
  allowSkip?: boolean
}

const getSegmentIndex = (segment: Seat.Train.Segment) => segment.index
const getInitialCarIndex = (segment: Seat.Train.Segment, seats?: Seat.Selected[]) => {
  if (seats?.[0]?.carIndex) return seats[0].carIndex

  return segment ? Math.min(...segment.cars.map(({ index }) => index)) : 0
}

const SeatSelectionTrain = (props: Props) => {
  const {
    opened,
    connection,
    selectedFare,
    onClose,
    scheme,
    layoutQuery,
    reservedSeatsCount,
    onSubmit,
    onClear,
    onFareClassChange,
    disableFare,
    allowSkip,
    initialSelection,
  } = props
  const isMobile = useIsMobile()
  const selection = useItemsSelection(initialSelection)
  // ToDo: Return trips are not supported, we are waiting for a design
  const tripDirection = 'outbound'
  const selectedTripSeats = useMemo(() => selection.items[tripDirection], [selection.items])
  const segmentList = useLinkedListWithIndex<Seat.Train.Segment>(scheme, getSegmentIndex)
  const schemeSegment = segmentList.current.item!

  const currentSegmentIndex = schemeSegment?.index
  const selectedSegmentSeats = selectedTripSeats[currentSegmentIndex] ?? []

  const fareOptions = useMemo(
    () =>
      fare
        .getUniqueFareClasses([connection], {})
        .map(({ code, name }) => ({ value: code, label: name }))
        .sort((a, b) => a.value.localeCompare(b.value, undefined, { numeric: true })),
    [connection],
  )

  const connectionSegment = useMemo(
    () => connection.segments.find(({ index }) => index === currentSegmentIndex)!,
    [currentSegmentIndex, connection],
  )

  const [selectedCar, setSelectedCar] = useState<number>(() => getInitialCarIndex(schemeSegment, selectedSegmentSeats))

  const segmentsCount = connection.segments.length
  const maxSegmentCount = scheme.length
  const fareClassName = fareOptions.find(({ value }) => value === selectedFare)?.label
  const farePrice = connection.fares.find(fare => fare.fareClass.code === selectedFare)?.price
  const seatsPrice = seatSelectionUtils.sumPrice(selectedSegmentSeats)

  const handleSubmit = (): void => {
    const nextSegment = segmentList.current.next?.item

    if (nextSegment) {
      setSelectedCar(getInitialCarIndex(nextSegment, selectedTripSeats[nextSegment.index]))
      segmentList.move(1)
    } else {
      onSubmit({ seats: selection.items })
    }
  }

  const layoutQueryResult = useQuery<Seat.Train.Layout>(
    layoutQuery(schemeSegment?.cars[selectedCar].layout, currentSegmentIndex, selectedCar),
  )

  useEffect(() => {
    const hasUnlabeledSeats = selectedSegmentSeats.some(seat => !seat.label)
    // Preselected seats don't have a label value, so we need to take it from the layout data
    if (hasUnlabeledSeats) {
      selection.setSegment?.(
        selectedSegmentSeats.map(seat =>
          seat.label
            ? seat
            : { ...seat, label: layoutQueryResult.data?.seats.find(({ code }) => code === seat.code)?.label },
        ),
        tripDirection,
        currentSegmentIndex,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutQueryResult.data?.seats])

  return (
    <Modal
      title={<Header seats={layoutQueryResult.data?.seats ?? []} />}
      opened={opened}
      onClose={onClose}
      closeAfterTransition
      footer={
        isMobile && (
          <TrainSubmitSection
            selectedSeats={selectedSegmentSeats}
            continueDisabled={selectedSegmentSeats.length !== reservedSeatsCount}
            seatsCount={reservedSeatsCount}
            fareClassName={fareClassName}
            farePrice={farePrice}
            seatsPrice={seatsPrice}
            onSubmit={handleSubmit}
            onClear={onClear}
            allowSkip={allowSkip}
            schemeSegment={schemeSegment}
          />
        )
      }
      fullScreen
      fullWidth
      fullHeight
      maxWidth="lg"
      className="train-seats-modal"
    >
      {scheme && (
        <TrainSeatSelectionContext.Provider
          value={{
            fareClass: selectedFare,
            segmentsCount,
            fareOptions,
            setFareClass: onFareClassChange,
            carIndex: selectedCar,
            setCarIndex: setSelectedCar,
            onSubmit: handleSubmit,
            onSelect: seat => selection.toggle(seat, tripDirection, currentSegmentIndex),
            selectedSeats: selectedSegmentSeats,
            opened,
            connection,
            scheme,
            currentSegment: segmentList.current.position,
            disableFare,
            reservedSeatsCount,
            onNavigateBack: () => segmentList.move(-1),
            maxSegmentCount,
            schemeSegment,
            connectionSegment,
            layout: layoutQueryResult.data,
            allowSkip,
            onClear,
          }}
        >
          {isMobile ? <TrainSeatsMobile /> : <TrainSeatsDesktop />}
        </TrainSeatSelectionContext.Provider>
      )}
    </Modal>
  )
}

export default SeatSelectionTrain
